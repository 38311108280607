import React from 'react';
import BillingEmail from '../../../components/Account/BillingEmail';
import PrivateRoute from '../../../components/PrivateRoute';
import ContextProvider from '../../../components/ContextProvider';

const BillingEmailPageEnAu = () => (
  <ContextProvider localeCode="en-AU">
    <PrivateRoute
      path="/en-AU/account/billing-email"
      component={BillingEmail}
    />
  </ContextProvider>
);

export default BillingEmailPageEnAu;
