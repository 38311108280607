import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input } from '@rebass/forms/styled-components';
import { httpsCallable } from 'firebase/functions';
import { navigate } from 'gatsby';
import React, { createRef, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Flex } from 'rebass/styled-components';
import { fetchAndUpdateUser } from '../../utils/Auth';
import { functions } from '../../utils/Firebase';
import { LocaleContext, UserContext } from '../ContextProvider';
import { Layout } from '../Layout';
import {
  BackLink,
  ContentContainer,
  ErrorMessage,
  Label,
  Row,
  Title,
} from '../LayoutComponents';
import { AuthContext } from '../PrivateRoute';
import Section from '../Section';

enum UpdateEmailErrorCodes {
  INVALID_EMAIL = 'auth/invalid-email',
  EMAIL_ALREADY_IN_USE = 'auth/email-already-in-use',
  REQUIRES_RECENT_LOGIN = 'auth/requires-recent-login',
  WRONG_PASSWORD = 'auth/wrong-password',
}

const BillingEmailPage = () => {
  const { t } = useTranslation([
    'billingEmail',
    'email',
    'commonLabels',
    'errorLabels',
  ]);
  const localeData = useContext(LocaleContext);
  const { rootPath } = localeData;
  const authData = useContext(AuthContext);
  const { authUser } = authData;
  const userContext = useContext(UserContext);
  const { updateUserContext } = userContext;
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [errorSaving, setErrorSaving] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [confirmationEmail, setConfirmationEmail] = useState<string>('');

  const emailInputRef = createRef();
  const confirmationEmailInputRef = createRef();

  const updateEmail = async () => {
    setIsSaving(true);
    setErrorSaving(false);

    if (authUser && email) {
      // If there were no errrors re-authenticating the user account then update the email address
      // then redirect back to the account page
      try {
        // const a = httpsCallable();
        const updateStripeCustomer = httpsCallable(
          functions,
          'billing-updateStripeCustomer',
        );

        await updateStripeCustomer({ email: email });
      } catch (error) {
        setErrorSaving(true);
        setIsSaving(false);
        return;
      }

      const updatedUser = await fetchAndUpdateUser(authUser);

      updateUserContext({
        userContext: {
          ...userContext,
          user: updatedUser.user,
          billing: updatedUser.billing,
        },
      });
      navigate(`${rootPath}account`);
    } else {
      setErrorSaving(true);
    }

    setIsSaving(false);
  };

  const spinningIcon = <FontAwesomeIcon icon={faSpinner} pulse />;

  // conditions that must be met in order to enable the save button
  const canSubmit =
    !authUser || isSaving || email !== confirmationEmail || !email;

  return (
    <Layout
      title={`${t('title')} - CloudFit`}
      description={t('metaDescription')}
      colorTheme="light">
      <Section.Container backgroundColor="light" id="billingEmail.id">
        <BackLink to={`${rootPath}account`}>
          {t('back', { ns: 'commonLabels' })}
        </BackLink>

        <Flex
          as="form"
          variant="card"
          px={[0]}
          py={[3]}
          flexDirection="column"
          onSubmit={(event) => {
            event.preventDefault();
            updateEmail();
          }}>
          <Title title={t('title')} summary={t('description')} />

          <Row>
            <Label>{t('newEmailLabel', { ns: 'email' })}</Label>

            <ContentContainer>
              <Input
                ref={emailInputRef}
                id="email"
                name="email"
                type="email"
                placeholder={t('newEmailPlaceholder', { ns: 'email' })}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                disabled={!authUser || isSaving}
                required
              />
            </ContentContainer>
          </Row>

          <Row>
            <Label>{t('confirmEmailLabel', { ns: 'email' })}</Label>

            <ContentContainer>
              <Input
                ref={confirmationEmailInputRef}
                id="confirmEmail"
                name="confirmEmail"
                type="email"
                placeholder={t('confirmEmailPlaceholder', { ns: 'email' })}
                onChange={(event) => {
                  setConfirmationEmail(event.target.value);
                }}
                sx={{
                  borderColor: confirmationEmail === email ? '' : 'cloudFitRed',

                  ':focus': {
                    outlineColor:
                      confirmationEmail === email ? '' : 'cloudFitRed',
                  },
                }}
                disabled={!authUser || isSaving}
                required
              />
            </ContentContainer>
            <ErrorMessage visible={email !== confirmationEmail}>
              {t('errorEntryMismatch', { ns: 'errorLabels' })}
            </ErrorMessage>
          </Row>

          <Row bottom>
            <Flex
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              width={[1]}>
              <Button
                type="button"
                variant="secondary"
                minWidth="100px"
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`${rootPath}account`);
                }}>
                {t('cancelButton', { ns: 'commonLabels' })}
              </Button>
              <Button
                type="submit"
                ml={[2]}
                disabled={canSubmit}
                minWidth="100px">
                {isSaving
                  ? spinningIcon
                  : t('submitButton', { ns: 'commonLabels' })}
              </Button>
            </Flex>
            <ErrorMessage visible={errorSaving} rightAlign>
              {t('errorCantSave', { ns: 'errorLabels' })}
            </ErrorMessage>
          </Row>
        </Flex>
      </Section.Container>
    </Layout>
  );
};

export default BillingEmailPage;
